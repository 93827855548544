import logo from './logo.svg';
import './App.css';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import axios from "axios";
import {useState} from "react";

function App() {


const baseUrl = 'https://apps.kenes.com/Web2LeadService/';
//const baseUrl = 'http://localhost:49147/';

    const [submitLoading, setSubmitLoading] = useState(false);

  const GET = url => {
    return axios.get(`${baseUrl}/${url}`);
  }

  const headers = {};

  const insertLeadDataFunc = (actionName, data, successCallback, failCallback) => {
    debugger;
    return axios
        .post(`${baseUrl}Values/${actionName}`, data)
        .then(response => {
          successCallback(response);
        })
        .catch(function (error) {
          failCallback(error);
          console.log(error);
        });
  }

  const insertLeadData = (actionName, data, successCallback, failCallback) => {

    if (typeof grecaptcha !== 'undefined') {
      window.grecaptcha.ready(function () {
        window.grecaptcha.execute('6Lc4pIUUAAAAAG-r76A6RhcMTiI4Bh0JKi1Mv7qW', { action: 'submit' })
            .then(function (token) {
              debugger;
              if (data == null) {
                data = {};
              }
              data['token'] = token;
              insertLeadDataFunc(actionName, data, successCallback, failCallback);
            });
      });
    }

  }

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [participants, setParticipants] = useState('');
    const [company, setCompany] = useState('');
    const [eventWebsite, setEventWebsite] = useState('');
    const [budget, setBudget] = useState('');
    const [interest, setInterest] = useState('');
    const [message, setMessage] = useState('');


    const interestList = [
    {
      id: 0,
      name: 'Conference Management',
    },
    {
      id: 1,
      name: 'Virtual & hybrid solutions',
    },
    {
      id: 2,
      name: 'Association management',
    },
    {
      id: 3,
      name: 'Continuing medical education',
    },
    {
      id: 4,
      name: 'Anything else',
    },
  ];

  const mySubmitHandler = (event) => {
    debugger;
    event.preventDefault();

      setSubmitLoading(true);
      insertLead('InsertCompanyLead', {
          firstName: event.target.firstName.value,
          lastName: event.target.lastName.value,
          email: event.target.email.value,
          participants: event.target.participants.value,
          company: event.target.company.value,
          eventWebsite: event.target.eventWebsite.value,
          budget: event.target.budget.value,
          //interest: event.target.interest.value,
          interest: document.getElementById('interest').innerText,
          message: event.target.message.value,
        },
        (result) => {
          debugger;
          alert('Thank you for your submission. Our representative will contact you shortly');
            setSubmitLoading(false);
          window.location.href = 'https://kenes-group.com/contact-us/';
        },
        (error) => {
          debugger;
          alert("submit unseccessful. please try again in a few minutes.");
            setSubmitLoading(false);
        }
    );

  }

  const insertLead = (actionName, data, successCallback, errorCallback) => {
    insertLeadData(
        actionName,
        data,
        (result) => {
          successCallback(result);
        },
        (error) => {
          errorCallback(error);
          debugger;
        });
  }


    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            //const color = chroma(data.color);
            return {
                backgroundColor: isDisabled ? 'red' : 'blue',
                color: '#FFF',
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
    };

  return (
      <div className="App">
        <div style={{marginTop: 24,}}>
          <form onSubmit={mySubmitHandler} autoComplete="off">
            <table style={{borderSpacing: "50px 15px", width: "100%"}}>
              <tbody>
              <tr>
                <td>
                  <TextField
                      required
                      id="firstName"
                      label="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      autoComplete="new-firstName"/>
                </td>
                <td>
                  <TextField
                      required
                      id="lastName"
                      label="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      autoComplete="new-lastName"/>
                </td>
                <td>
                  <TextField
                      required
                      id="email"
                      type="email"
                      label="Your Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                  />
                </td>
                <td>
                  <TextField
                      type="number"
                      id="participants"
                      label="Expected Participants"
                      value={participants}
                      onChange={(e) => setParticipants(e.target.value)}
                      autoComplete="new-participants"/>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                      required
                      id="company"
                      label="Association / Company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      autoComplete="new-company"/>
                </td>
                <td>
                  <TextField
                      id="eventWebsite"
                      label="Your Event Website"
                      value={eventWebsite}
                      onChange={(e) => setEventWebsite(e.target.value)}
                      autoComplete="new-website"/>
                </td>
                <td>
                  <TextField
                      id="budget"
                      label="Approximate budget"
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                      autoComplete="new-budget"/>
                </td>
                <td>
                  <FormControl>
                    <InputLabel htmlFor="interest">Interested in</InputLabel>
                    <Select
                        id="interest"
                        style={{width: '223px', display: 'inline'}}
                        styles={colourStyles}
                        required
                        value={interest}
                        onChange={(e) => {
                          setInterest(e.target.value);
                        }}
                        options={interestList}>
                      {interestList.map((interest, index) => (
                          <MenuItem key={index} value={interest.id}>
                            {interest.name}
                          </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </td>
              </tr>
              <tr>
                <td colSpan="4">
                  <TextField
                      id="message"
                      label="Your Message"
                      required
                      multiline
                      maxLength={13000}
                      value={message}
                      onChange={(e) => setMessage( e.target.value)}
                      style={{width: '95%'}}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="4" style={{textAlign: 'center'}}>
                  {/*<Button type="submit" variant="outlined" size="large" >Submit</Button>*/}
                    <LoadingButton
                        type="submit"
                        size="large"
                        endIcon={<SendIcon />}
                        loading={submitLoading}
                        loadingPosition="end"
                        variant="outlined"
                    >
                        <span>Send</span>
                    </LoadingButton>
                </td>
              </tr>
              </tbody>
            </table>
          </form>

        </div>

      </div>
  );
}

export default App;
